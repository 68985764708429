<template>
	<div padding="0" class="app-bar !bg-primary-500 p-0 text-white main-app-bar transition z-40">
		<div class="max-w-[1536px] mx-auto py-4 px-6 lg:px-11">
			<NavBarMain />
		</div>
	</div>
</template>

<script>
import NavBarMain from "~/components/nav/NavBarMain.vue";

export default {
	name: "NavBar",
	components: {
		NavBarMain
	}
};
</script>
