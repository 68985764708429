import WheelsService from '@/services/WheelsService'
import WPService from '@/services/WPService'
import AuthService from '@/services/AuthService'

export default (ctx, inject) => {
    const config = ctx.$config

    const services = {
        wheels: WheelsService(config.baseURL, config.wpURL, config.wheelsAuth),
        wp: WPService(config.wpURL),
        auth: AuthService,
    }

    inject('services', services)
}