<template>
	<v-app id="app">
		<div class="antialiased bg-gray-100 font-roboto pb-16 md:pb-0">
			<v-navigation-drawer id="nav2" :width="240" style="z-index: 1" v-model="isOpenCartDrawer" class="pb-0 w-80" fixed app touchless>
				<aside class="w-60 lg:block bg-primary-500 fixed top-0 inset-y-0 z-10 h-full">
					<div class="border-b-2 h-[85px] mx-4 flex items-center justify-center border-black">
						<nuxt-link :to="localePath('/')">
							<img src="~/assets/img/logo_sirius-white-star.svg" class="h-8 md:h-10 w-auto" alt="" />
						</nuxt-link>
					</div>
					<div class="">
						<MyAccountSidebar />
					</div>
				</aside>
			</v-navigation-drawer>
			<main class="xl:pl-60 bg-gray-100 min-h-screen relative flex flex-col h-full">
				<NavBarSecondary />
				<Nuxt />
				<v-btn @click.stop="isOpenCartDrawer = !isOpenCartDrawer" class="xl:hidden bg-primary-500" color="darkblue" dark fixed bottom right fab>
					<v-icon v-if="isOpenCartDrawer">{{ svgClose }}</v-icon>
					<v-icon v-else>{{ svgTune }}</v-icon>
				</v-btn>
				<Footer />
			</main>
		</div>
	</v-app>
</template>

<script>
import Cart from "@/components/Cart";
import { mdiCarClock, mdiClose } from "@mdi/js";
import Footer from "~/components/Footer";
import MyAccountSidebar from "~/components/MyAccountSidebar";
import NavBarSecondary from "~/components/nav/NavBarSecondary";
export default {
	name: "CheckoutLayout",
	components: {
		NavBarSecondary,
		Footer,
		Cart,
		MyAccountSidebar
	},
	data() {
		return {
			showMobileMenu: false,
			isOpenCartDrawer: this.drawerVisible,
			svgTune: mdiCarClock,
			svgClose: mdiClose
		};
	},
	computed: {
		drawerVisible() {
			return this.$helpers.functions.drawerVisible(this.$vuetify.breakpoint.name);
		}
	},
	transition: {
		name: "page",
		mode: "out-in"
	},
	watch: {
		$route() {
			this.showMobileMenu = false;
		}
	}
};
</script>
