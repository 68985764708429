import me from "~/apollo/queries/me";
import loginGql from "~/apollo/mutations/login";
import logoutGql from "~/apollo/mutations/logout";
import updateUser from "~/apollo/mutations/updateUser";
import registerUser from "~/apollo/mutations/registerUser";

export default {
	async login(apollo, credentials) {
		return await apollo.mutate({
			mutation: loginGql,
			variables: credentials
		});
	},

	async logout(apollo) {
		return await apollo.mutate({
			mutation: logoutGql
		});
	},

	async getLoggedInUser(apollo) {
		return await apollo.query({
			query: me,
      fetchPolicy: 'network-only',
		});
	},

	async updateUser(apollo, payload) {
		return await apollo.mutate({
			mutation: updateUser,
			variables: payload
		});
	},

	async register(apollo, payload) {
		return await apollo.mutate({
			mutation: registerUser,
			variables: payload
		});
	}
};
