<template>
	<div class="mx-auto w-full">
		<nav class="car-navbar-blue sm:flex grow bg-primary-500 relative">
			<div class="flex justify-between w-full">
				<div class="flex items-center">
					<nuxt-link :to="localePath('/')">
						<img class="relative mr-5 h-14 w-auto" src="~/assets/img/logo_sirius-white-star.svg" alt="Logo Header" />
						<div class="bg-white w-px h-full"></div>
					</nuxt-link>
					<div class="lg:border-l pl-5 flex items-center">
						<div class="hidden sm:flex flex-col align-center !text-black">
							<div class="flex">
								<v-icon color="black">{{ mdiPhoneInTalk }}</v-icon>
								<span class="font-bold text-sm">+30 210 9919979</span>
							</div>
							<a href="mailto:info@siriusrentacar.gr" class="font-bold text-xs">info@siriusrentacar.gr</a>
						</div>
					</div>
				</div>
				<div class="hidden sm:ml-9 lg:ml-0 lg:flex items-center font-medium uppercase">
					<div class="flex border-none justify-center items-center text-xs gap-5 text-white">
						<div class="group flex items-center hover:text-black cursor-pointer transition duration-200">
							<span @mouseover="servicesOpen = true">Services</span>
							<div class="block">
								<svg class="w-4 pr-1 rotate-180" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" /></svg>
							</div>
						</div>
						<nuxt-link @mouseover.native="servicesOpen = false" :to="localePath('/vehicles')" class="hover:text-black cursor-pointer transition duration-200">{{ $t("_navbarmain.vehicles") }}</nuxt-link>
						<nuxt-link @mouseover.native="servicesOpen = false" :to="localePath('/locations')" class="hover:text-black cursor-pointer transition duration-200">{{ $t("_navbarmain.locations") }}</nuxt-link>
						<nuxt-link @mouseover.native="servicesOpen = false" :to="localePath('/about')" class="hover:text-black cursor-pointer transition duration-200">{{ $t("_navbarmain.about") }}</nuxt-link>
						<nuxt-link @mouseover.native="servicesOpen = false" :to="localePath('/contact')" class="hover:text-black cursor-pointer transition duration-200">{{ $t("_navbarmain.contact") }}</nuxt-link>
					</div>
				</div>
				<div class="flex items-center text-black justify-end">
					<button class="text-xs flex-row justify-end pr-6 hidden lg:flex hover:text-white">
						<div>
							<svg class="pr-1" xmlns="http://www.w3.org/2000/svg" width="16.31" height="16.31" viewBox="0 0 16.31 16.31">
								<g id="icon_search" transform="translate(0.75 0.75)">
									<circle id="Ellipse_1" data-name="Ellipse 1" cx="6.973" cy="6.973" r="6.973" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
									<line id="Line_1" data-name="Line 1" x2="2.479" y2="2.616" transform="translate(12.021 11.883)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
								</g>
							</svg>
						</div>
						<div>Search keyword</div>
					</button>

					<div class="lg:flex items-center text-black">
						<LanguageSwitcher class="lg:block" />
						<ProfileAvatar class="hidden lg:block pl-3 border-black border-l-2" />
					</div>
					<div class="ml-3 mb-2 relative">
						<NavMenu class="lg:hidden menu-button" />
					</div>
				</div>
			</div>
			<div v-show="servicesOpen" @mouseleave.self="servicesOpen = false" class="absolute w-full bg-white top-28 h-136">
				<div v-if="loading" class="flex items-center">
					<v-skeleton-loader :width="320" type="list-item-two-line"></v-skeleton-loader>
					<v-skeleton-loader :width="320" type="list-item-three-line"></v-skeleton-loader>
					<v-skeleton-loader :width="320" type="list-item-two-line"></v-skeleton-loader>
					<v-skeleton-loader :width="320" type="list-item-three-line"></v-skeleton-loader>
				</div>
				<div v-else class="px-9 py-14 grid grid-col-1 md:grid-cols-2 lg:grid-cols-4 gap-x-16 h-full z-50">
					<div class="flex flex-col justify-between h-full">
						<h4 class="font-bold text-primary-500 text-lg">{{$helpers.functions.$t(services[0], this.$i18n.locale)?.title}}</h4>
						<p class="text-gray-500">{{services[0]?.menu_texts.menuTexts[this.$i18n.locale+'Text']}}</p>
						<img class="mx-auto h-52 w-9/12 object-contain" :src="services[0]?.featuredImage?.node?.mediaItemUrl" alt="Services" />
						<nuxt-link :to="localePath('/services/'+services[0]?.slug)" class="text-center mb-2 text-white hover:scale-105 w-1/2 bg-primary-500 p-2">{{$t('_navbarmain.more')}}</nuxt-link>
					</div>
					<div class="flex flex-col justify-between h-full">
						<h4 class="font-bold text-primary-500 text-lg">{{$helpers.functions.$t(services[1], this.$i18n.locale)?.title}}</h4>
						<p class="text-gray-500">{{services[1]?.menu_texts.menuTexts[this.$i18n.locale+'Text']}}</p>
						<img class="mx-auto h-52 w-9/12 object-contain" :src="services[1]?.featuredImage?.node?.mediaItemUrl" alt="Services" />
						<nuxt-link :to="localePath('/services/'+services[1]?.slug)" class="text-center mb-2 text-white hover:scale-105 w-1/2 bg-primary-500 p-2">{{$t('_navbarmain.more')}}</nuxt-link>
					</div>
					<div class="flex flex-col justify-between h-full">
						<h4 class="font-bold text-primary-500 text-lg">{{$helpers.functions.$t(services[2], this.$i18n.locale)?.title}}</h4>
						<p class="text-gray-500">{{services[2]?.menu_texts.menuTexts[this.$i18n.locale+'Text']}}</p>
						<img class="mx-auto h-52 w-9/12 object-contain" :src="services[2]?.featuredImage?.node?.mediaItemUrl" alt="Services" />
						<nuxt-link :to="localePath('/services/'+services[2]?.slug)" class="text-center mb-2 text-white hover:scale-105 w-1/2 bg-primary-500 p-2">{{$t('_navbarmain.more')}}</nuxt-link>
					</div>
					<div class="flex flex-col justify-between h-full">
						<h4 class="font-bold text-primary-500 text-lg">{{$helpers.functions.$t(leases[0], this.$i18n.locale)?.title}}</h4>
						<p class="text-gray-500">{{leases[0]?.menu_texts.menuTexts[$i18n.locale+'Text']}}</p>
						<img class="mx-auto h-52 w-9/12 object-contain" :src="require('@/assets/img/services-4.png')" alt="Services" />
						<nuxt-link :to="localePath('/leases')" class="text-center mb-2 text-white hover:scale-105 w-1/2 bg-primary-500 p-2">{{$t('_navbarmain.more')}}</nuxt-link>
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
import { mdiChevronUp, mdiPhoneInTalk } from "@mdi/js";
import LanguageSwitcher from "~/components/LanguageSwitcher.vue";
import NavMenu from "~/components/nav/NavMenu";
import ProfileAvatar from "~/components/profileAvatar.vue";
import services from "@/apollo/queries/services";
import leases from "@/apollo/queries/leases";

export default {
	components: {
		NavMenu,
		LanguageSwitcher,
		ProfileAvatar
	},
	data() {
		return {
			mdiChevronUp,
			mdiPhoneInTalk,
			servicesOpen: false,
			services: [],
			leases: [],
			loading: false
		};
	},
	fetchOnServer: false,
	async fetch() {
		this.loading = true
		let res = await this.$apollo.query({ query: services });
		this.services = res?.data?.posts?.nodes
		res = await this.$apollo.query({ query: leases });
		this.leases = res?.data?.posts?.nodes
		this.loading = false
	},
};
</script>

<style scoped>
.link {
	color: #ffffff;
}

.link:hover {
	color: rgb(255 203 5 / var(--tw-text-opacity));
}
</style>
