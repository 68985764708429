<template>
	<div id="lang_select" class="lang-switcher flex w-14 mr-3">
		<v-select :class="classes" ref="lang" :items="availableLocales" v-model="selectedLocale" @change="switchLang" :hide-detail="true" hide-details></v-select>
	</div>
</template>

<script>
export default {
	data() {
		return {
			selectedLocale: this.$i18n.locale.toUpperCase()
		};
	},
	props: {
		classes: {
			type: String,
			default: 'hover:text-white'
		}
	},
	computed: {
		availableLocales() {
			return this.$i18n.locales.map(item => item.code.toUpperCase());
		},
	},
	watch: {
		selectedLocale: function (newVal, oldVal) {
			this.$router.push(this.switchLocalePath(newVal.toLowerCase()));
		},
		"$i18n.locale": {
			handler: function (locale) {
				this.selectedLocale = locale.toUpperCase()
			}
		}
	},
	methods: {
		switchLang() {
			this.$router.push(this.switchLocalePath(this.$refs.lang.value.toLowerCase()));
		}
	}
};
</script>

<style>
.v-text-field {
	padding-top: 0;
	margin-top: 0;
}
#lang_select .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
	border-style: none !important;
}
.active span {
	color: #ffcb05 !important;
	background-color: #0061af;
	border-radius: 5px;
}
.theme--light.v-select .v-select__selections {
	color: inherit;
}
</style>
