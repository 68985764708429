<template>
	<v-app id="app" class="!bg-gray-100">
		<NavBar />
		<Nuxt />
		<FooterBoxed />
	</v-app>
</template>

<script>
import FooterBoxed from "~/components/FooterBoxed.vue";
import NavBar from "~/components/nav/NavBar.vue";
export default {
	name: "DefaultLayout",
	components: {
		NavBar,
		FooterBoxed
	},
	transition: {
		name: "page",
		mode: "out-in"
	},
	watch: {
		$route() {
			this.showMobileMenu = false;
		}
	},
	data() {
		return {
			showMobileMenu: false
		};
	}
};
</script>
