import axios from 'axios';

const clientAPI = options => {
  return axios.create({
    baseURL: options.url,
    headers: {
      ...options.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
}

export default clientAPI;