<template>
	<v-app id="app" class="bg-white text-black">
		<v-container class="mx-auto flex items-center">
			<div class="grid grid-cols-2 items-center w-full gap-5">
				<div class="flex justify-end">
					<div>
						<h3 class="text-primary-500 font-bold text-4xl mr-2">{{ this.error.statusCode }}</h3>
					</div>
					<div>
						<div class="border-l-2 border-gray-300 pl-2">
							<h3 class="font-bold text-4xl">
								<span>{{ $t("_404.title") }}</span>
							</h3>
							<p>
								<span>{{ $t("_404.content") }}</span>
							</p>
						</div>
						<div class="flex gap-8">
							<nuxt-link :to="localePath('/')" class="hover:scale-105 text-sm lg:text-base primary-btn !text-white !bg-primary-500"
								><span>{{ $t("_404.home_btn") }}</span></nuxt-link
							>
							<nuxt-link :to="localePath('/contact')" class="hover:scale-105 text-sm lg:text-base primary-btn !bg-gray-100 !text-primary-500">
								<span>{{ $t("_404.contact_btn") }}</span>
							</nuxt-link>
						</div>
					</div>
				</div>
				<div>
					<img src="~/assets/img/illustration_car-crashed-404.svg" alt="" />
				</div>
			</div>
		</v-container>
	</v-app>
</template>
<script>
import { mdiAlertCircleOutline } from "@mdi/js";

export default {
	layout: "empty",
	props: {
		error: {
			type: Object,
			default: null
		}
	},
	data() {
		return {
			svgAlertCircleOutline: mdiAlertCircleOutline,
			pageNotFound: "404 Not Found",
			otherError: "An error occurred"
		};
	},
	head() {
		const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
		return {
			title
		};
	},

	computed: {
		errorHeading() {
			return this.error.statusCode === 404 ? "Page not found" : "Oops!";
		},

		errorText() {
			return this.error.statusCode === 404 ? "Please check the URL in the address bar and try again." : "Something went wrong.";
		}
	}
};
</script>

<style scoped>
@media (min-width: 1904px) {
	.container {
		max-width: 1185px;
	}
	.container--fluid {
		max-width: 100%;
	}
}
</style>
