export default {
	formatTo2Decimals(number) {
		number = parseInt(number * 100) / 100;
		const res = new Intl.NumberFormat("el-GR", {
			style: "currency",
			currency: "EUR",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		}).format(number);
		return res;
	},

	formatToDate(date, locale) {
		let d = new Date(date);
		let localeCode = locale == 'el' ? 'el-GR' : 'en-US'
		return d.toLocaleDateString(localeCode, {dateStyle: 'full'})
	},

	drawerVisible(arg) {
		switch (arg) {
			case "xs":
				return false;
			case "sm":
				return true;
			case "md":
				return true;
			case "lg":
				return true;
			case "xl":
				return true;
		}
	},

	$t(arg, locale) {
		if (arg?.translations?.[locale]) {
			arg = { ...arg, ...arg.translations[locale] };
			arg.content = arg.translations[locale].excerpt;
		}
		return arg;
	},

	formattedDate(date, loc) {
		if (date) {
			let locale = loc == "el" ? "el-GR" : "en-US";
			const newDate = new Date(date).toLocaleDateString(locale, { weekday: "short", year: "numeric", month: "short", day: "numeric" });
			return newDate;
		} else {
			return "";
		}
	}
};
