<template>
	<div>
		<footer class="!bg-black !text-white py-14">
			<div class="mx-auto px-5 sm:px-10 lg:px-11">
				<div class="flex mb-8">
					<div class="flex">
						<div class="pr-6">
							<v-img :src="require('@/assets/img/logo_sirius-full-white.svg')" alt="Company Logo"></v-img>
						</div>
						<div class="flex pl-6 border-l-2 border-white items-center">
							<a v-for="(icon, key) in socialIcons" :key="key" :href="icon.url">
								<v-icon color="white" class="px-4">{{ icon.icon }}</v-icon>
							</a>
						</div>
					</div>
					<div>
						<LanguageSwitcher class="hidden lg:block" classes="!text-white hover:text-black" />
					</div>
				</div>
				<div class="mb-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 w-full gap-4">
					<div class="flex flex-col">
						<h4 class="font-bold mb-3 text-sm">Services</h4>
						<div v-if="loading" class="flex items-center">
							<v-skeleton-loader width="200" type="text"></v-skeleton-loader>
							<v-skeleton-loader width="200" type="text"></v-skeleton-loader>
							<v-skeleton-loader width="200" type="text"></v-skeleton-loader>
							<v-skeleton-loader width="200" type="text"></v-skeleton-loader>
						</div>
						<ul v-else class="text-xs flex flex-col list-none gap-y-2 !pl-0">
							<li v-for="(post, index) in services" :key="index">
								<nuxt-link :to="post?.slug == 'long-term-leases' ? localePath('/leases') : localePath('/services/' + post?.slug)">
									{{ post?.title }}
								</nuxt-link>
							</li>
						</ul>
					</div>
					<div class="flex flex-col">
						<h4 class="font-bold mb-3 text-sm">{{ $t('_footer.info') }}</h4>
						<ul class="text-xs flex flex-col list-none gap-y-2 !pl-0">
							<li><nuxt-link :to="localePath('/faq')">{{$t('faq.questions_short')}}</nuxt-link></li>
							<li><nuxt-link :to="localePath('/contact')">{{$t('_footer.contact')}}</nuxt-link></li>
						</ul>
					</div>
					<div class="flex flex-col text-base">
						<div class="flex items-center gap-2">
							<div class="text-white">
								<svg xmlns="http://www.w3.org/2000/svg" width="18.22" height="17.82" viewBox="0 0 18.22 17.82">
									<g id="icon_phone-volume" transform="translate(0.752 0.75)">
										<path id="Path_7191" data-name="Path 7191" d="M289.427,1677.983a14.445,14.445,0,0,0,7.3,4.2c1.223.084,2.062-.987,2.42-2.136.315-.988-1.109-2.1-2.262-2.747-1.706-1.019-2.065-.592-2.538.157" transform="translate(-285.198 -1665.871)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
										<path id="Path_7192" data-name="Path 7192" d="M244.183,1587.257a14.341,14.341,0,0,1-4.223-7.264c-.084-1.217.992-2.052,2.146-2.408.993-.314,2.111,1.1,2.761,2.25,1.024,1.7.6,2.055-.158,2.525" transform="translate(-239.956 -1575.147)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
										<path id="Path_7193" data-name="Path 7193" d="M344.439,1549.422a7.643,7.643,0,0,1,5.546,2.226,7.565,7.565,0,0,1,2.238,5.518" transform="translate(-335.506 -1549.421)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
										<path id="Path_7194" data-name="Path 7194" d="M343.617,1593.39a3.96,3.96,0,0,1,2.946,1.143,3.918,3.918,0,0,1,1.148,2.931" transform="translate(-334.755 -1589.646)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
									</g>
								</svg>
							</div>
							<a v-for="(phone, key) in phones" :key="key" :href="'tel:' + phone.number" v-if="phones.length">{{ phone.number }}</a>
							<v-skeleton-loader v-if="!phones.length" width="200" type="text"></v-skeleton-loader>
						</div>
						<div class="flex items-center gap-2">
							<div class="text-white">
								<svg xmlns="http://www.w3.org/2000/svg" width="17.149" height="16.573" viewBox="0 0 17.149 16.573">
									<g id="icon_envelope-open" transform="translate(0.75 0.823)">
										<path id="Path_5931" data-name="Path 5931" d="M588.66-541.855l-7.558,3.872a.851.851,0,0,1-.788-.006l-7.254-3.841a.034.034,0,0,0-.05.031v9.012a.87.87,0,0,0,.863.876H587.8a.87.87,0,0,0,.863-.876Z" transform="translate(-573.011 546.911)" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="1.5" />
										<path id="Path_5932" data-name="Path 5932" d="M573.02-544.1l6.315-4.552a2.6,2.6,0,0,1,3.049,0l6.269,4.547" transform="translate(-573.014 549.149)" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="1.5" />
									</g>
								</svg>
							</div>
							<a v-if="!!email" :href="'mailTo:'+email">{{ email }}</a>
							<v-skeleton-loader v-if="!email" width="200" type="text"></v-skeleton-loader>
						</div>
						<v-skeleton-loader v-if="!address" width="200" type="text"></v-skeleton-loader>
						<a href="https://www.google.com/maps/place/SIRIUS+RENT+A+CAR/@37.9385705,23.7543203,17z/data=!3m1!4b1!4m6!3m5!1s0x14a1bd85e8b91c9b:0x903c9a849d65bce5!8m2!3d37.9385705!4d23.756509!16s%2Fg%2F11c6f32xxq" target="_blank" rel="noopener noreferrer" class="mt-4">{{ address }}</a>
					</div>
					<div class="flex flex-col order-8">
						<a
							href="https://www.aetoitismihanokinisis.eu/profile-37625-sirius-rent-a-car"
							target="_blank"
							rel="noreferrer"
							>
						<img src="~/assets/img/logo_trophy_auto_moto_gr.png" class="brightness-150 hover:brightness-100" alt="aetoitismihanokinisis" loading="lazy" />
						</a>
					</div>
				</div>
				<div class="flex flex-col text-xs gap-1">
					<span>EOT Signal Number - Greek National Tourism</span>
					<span>Organization Badge: 0206Ε81000479800</span>
				</div>
			</div>
		</footer>
		<!-- Grey bar -->

		<div>
			<div class="flex mx-auto px-6 py-8 text-gray-500 flex-col lg:flex-row">
				<div class="flex gap-3 items-center justify-center lg:justify-start mx-auto lg:mx-0 flex-wrap">
					<div class="text-xs pr-2 lg:border-r border-gray-500">&copy; {{ new Date().getFullYear() }} <b>Sirius Car Rentals SA</b></div>
					<div class="flex flex-wrap flex-row items-center justify-center lg:justify-start mx-auto lg:mx-0">
						<nuxt-link :to="localePath(link.url)" class="footer-link text-[12px] truncate text-gray-500 pr-2 hover:text-primary-500 transition" v-for="(link, i) in links" :key="`i${i}`">{{ $t("_footer." + link.title) }} </nuxt-link>
						<span v-for="(link, k) in links" :key="`k${k}`" class="w-[1px] h-3 bg-gray-500/70 flex-shrink-0"></span>
					</div>
				</div>
				<div class="flex justify-end lg:justify-end items-center space-x-2 lg:w-auto mt-4 md:mt-0">
					<a class="flex items-center md:flex-nowrap flex-wrap" href="https://hellenictechnologies.com" title="Headless Car Rental Web App by Hellenic Technologies" _target="_blank">
						<span class="text-[12px] pr-2">Headless Car Rental Web App by</span>
						<img src="~/assets/img/web-development-hellenic-technologies.svg" class="h-5" alt="Headless Car Rental Web App by Hellenic Technologies" loading="lazy" />
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import companyQuery from "@/apollo/queries/company";
import leases from "@/apollo/queries/leases";
import services from "@/apollo/queries/services";
import LanguageSwitcher from "@/components/LanguageSwitcher";
import { mdiFacebook, mdiInstagram, mdiLinkedin } from "@mdi/js";
export default {
	name: "Footer",
	components: {
		LanguageSwitcher
	},
	data() {
		return {
			loading: true,
			services: [],
			socialIcons: [
				{
					title: "Facebook",
					icon: mdiFacebook,
					url: "https://www.facebook.com/siriusrentacar/"
				},
				{
					title: "Instagram",
					icon: mdiInstagram,
					url: "https://www.instagram.com/siriusrentacar/"
				},
				{
					title: "LinkedIn",
					icon: mdiLinkedin,
					url: "https://www.linkedin.com/company/sirius-rent-a-car-gr/"
				}
			],
			links: [
				{ title: "about", url: "/about" },
				{ title: "contact", url: "/contact" },
				{ title: "cookies", url: "/cookie-policy" },
				{ title: "privacy_policy", url: "/privacy-policy" },
				{ title: "terms", url: "/terms" }
			],
			companyInfo: null
		};
	},
	computed: {
		phones() {
			return this.companyInfo?.companyPhones ?? [];
		},
		email() {
			return this.companyInfo?.companyEmail ?? null;
		},
		address() {
			if (this.$i18n.locale.toLowerCase() == "el") {
				return this.companyInfo?.addressEl ?? null;
			} else {
				return this.companyInfo?.companyAddress ?? null;
			}
		}
	},
	apollo: {
		htechSettings: {
			prefetch: false,
			query: companyQuery
		}
	},
	async mounted() {
		const res = await this.$apollo.queries.htechSettings.refetch().then(res => res.data);
		this.companyInfo = res?.htechSettings?.acfOptionsPage;
	},
	fetchOnServer: false,
	async fetch() {
		this.loading = true
		let res = await this.$apollo.query({ query: services });
		this.services = res?.data?.posts?.nodes
		res = await this.$apollo.query({ query: leases });
		this.services = [...this.services, ...res?.data?.posts?.nodes]
		this.loading = false
	},
};
</script>

<style scoped>
ul {
	list-style-type: none;
}
</style>
