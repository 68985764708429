<template>
	<v-app id="app">
		<div class="antialiased bg-gray-100 font-roboto pb-16 md:pb-0">
			<v-navigation-drawer :width="240" id="nav2" style="z-index: 10" v-model="isOpenFiltersDrawer" class="pb-0" fixed app touchless>
				<aside class="w-full lg:w-60 lg:block bg-primary-500 fixed top-0 inset-y-0 z-10">
					<div class="border-b-2 h-[85px] mx-4 flex items-center justify-center border-black">
						<nuxt-link :to="localePath('/')">
							<img src="~/assets/img/logo_sirius.svg" class="h-8 md:h-10 w-auto" alt="" />
						</nuxt-link>
					</div>
					<div class="flex-1 overflow-y-scroll h-screen scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-400">
						<Filters />
					</div>
				</aside>
			</v-navigation-drawer>
			<main class="lg:pl-60 vehicles-container bg-gray-100 min-h-screen relative flex flex-col">
				<NavBarSecondary />
				<div class="xl:block bg-white pt-8 pb-5 px-6 w-full rounded-none shadow-sm">
					<SearchForm />
				</div>
				<Breadcrumbs />
				<div class="w-full px-5 sm:px-10 flex-1 py-5">
					<Nuxt />
				</div>
				<v-btn @click.stop="isOpenFiltersDrawer = !isOpenFiltersDrawer" class="xl:hidden bg-primary-500" color="darkblue" dark fixed bottom right fab>
					<v-icon v-if="isOpenFiltersDrawer">{{ svgClose }}</v-icon>
					<v-icon v-else>{{ svgTune }}</v-icon>
				</v-btn>
				<Footer />
			</main>
		</div>
	</v-app>
</template>
<style>
.vehicles-container {
	padding-left: 240px;
}
.v-text-field__details {
	display: none;
}
@media screen and (max-width: 1263px) {
	.vehicles-container {
		padding-left: 0;
	}
}
</style>
<script>
import Filters from "@/components/Filters";
import SearchForm from "@/components/SearchForm";
import { mdiClose, mdiTune } from "@mdi/js";
import Footer from "~/components/Footer";
import Breadcrumbs from "~/components/nav/Breadcrumbs";
import NavBarSecondary from "~/components/nav/NavBarSecondary";
export default {
	name: "InnerLayout",
	components: {
		NavBarSecondary,
		SearchForm,
		Breadcrumbs,
		Footer,
		Filters
	},
	data() {
		return {
			isOpenFiltersDrawer: this.drawerVisible,
			svgTune: mdiTune,
			svgClose: mdiClose,
			showMobileMenu: false
		};
	},
	computed: {
		drawerVisible() {
			return this.$helpers.functions.drawerVisible(this.$vuetify.breakpoint.name);
		}
	},
	transition: {
		name: "page",
		mode: "out-in"
	},
	watch: {
		$route() {
			this.showMobileMenu = false;
		}
	}
};
</script>
