<template>
	<div class="flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-400 scroll-pb-16 pb-16">
		<ClientOnly>
			<div v-if="this.selectedCar != null">
				<h4 class="p-4 text-white font-medium text-base">{{ $t("checkout.booking") }}</h4>
				<div class="w-full h-full flex items-center justify-center bg-white p-0">
					<img :src="carImage" class="object-contain w-full" alt="" />
				</div>
				<div class="divide-y divide-gray-300/50 px-4">
					<div>
						<h4 class="font-bold text-black pt-2">
							{{ carDescription }} ({{ carCode }}) <span v-show="carDescription" class="text-white text-sm font-normal">{{ $t("_cart.similar") }}</span>
						</h4>
						<div class="text-white text-sm pb-4 leading-3" v-if="!!carAvailability">{{carAvailability}}</div>
						<div class="flex items-center pb-7 space-x-3">
							<div class="flex items-center">
								<img src="~/assets/img/svg-icons/icon_transmission_white.svg" class="flex-shrink-0 w-5" alt="" />
								<span class="text-sm text-white ml-1">{{ carFuel }}</span>
							</div>
							<div class="flex items-center">
								<img src="~/assets/img/svg-icons/icon_passengers_white.svg" class="flex-shrink-0 w-5" alt="" />
								<span class="text-sm text-white ml-1">{{ carPassengers }}</span>
							</div>
							<div class="flex items-center">
								<img src="~/assets/img/svg-icons/icon_doors_white.svg" class="flex-shrink-0 w-5" alt="" />
								<span class="text-sm text-white ml-1"> {{ carDoors }}</span>
							</div>
							<div class="flex items-center">
								<img src="~/assets/img/svg-icons/icon_suitcases_white.svg" class="flex-shrink-0 w-5" alt="" />
								<span class="text-sm text-white ml-1">{{ carSuitcases }}</span>
							</div>
						</div>
					</div>
					<div class="py-7 space-y-5">
						<div class="space-y-0.5">
							<div class="text-xs text-white">{{ $t("_cart.pickup") }}</div>
							<div v-if="pickupLocation != null" class="text-black font-medium">{{ pickupLocation.name }}</div>
							<div class="text-black font-bold text-[15px]">{{ pickupDate }}, {{ pickupTime }}</div>
						</div>
						<div class="space-y-0.5">
							<div class="text-xs text-white">{{ $t("_cart.dropoff") }}</div>
							<div v-if="dropoffLocation != null" class="text-black font-medium">{{ dropoffLocation.name }}</div>
							<div class="text-black font-bold text-[15px]">{{ dropoffDate }}, {{ dropoffTime }}</div>
						</div>
					</div>
					<div class="py-7">
						<h4 class="text-white font-medium mb-7">{{ $t("_cart.price") }}</h4>
						<div class="divide-y divide-gray-300/50">
							<template v-if="chosenOptions.length">
								<div class="flex items-center justify-between py-2" v-for="(item, index) in chosenOptions" :key="index">
									<div class="text-black text-xs">{{ $t(`_json_options.${item.code.toLowerCase()}.name`) }}</div>
									<div class="text-black font-bold text-lg">{{ getChosenOptionCost(item.code, item.value) }}</div>
								</div>
							</template>
							<div v-if="carOutOfOffice" class="flex items-center justify-between py-2">
								<div class="text-black text-xs">{{ $t('_cart.outofoffice') }}</div>
								<div class="text-black font-bold text-lg">{{ carOutOfOffice }}</div>
							</div>
							<div class="flex items-center justify-between py-2">
								<div class="text-black text-xs">{{ $t("_cart.fee") }}</div>
								<div class="text-black font-bold text-lg">{{ carPrice }}</div>
							</div>
							<div class="flex items-center justify-between py-2">
								<div class="text-black text-xs">{{ $t("_cart.total") }}</div>
								<div class="text-black font-bold text-lg">{{ carPriceTotal }}</div>
							</div>
						</div>
	
						<div class="mt-6">
							<h4 class="text-white font-medium mb-3">{{ $t("_cart.includes") }}</h4>
							<div class="space-y-1 mt-2">
								<div class="flex items-start space-x-2 text-green-500">
									<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 flex-shrink-0 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
										<path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
									</svg>
									<div class="font-bold text-sm text-black">{{ $t("_cart.unlim_mileage") }}</div>
								</div>
								<div class="flex items-start space-x-2 text-green-500">
									<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 flex-shrink-0 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
										<path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
									</svg>
									<div class="font-bold text-sm text-black">{{ $t("_cart.assistance") }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ClientOnly>
	</div>
</template>

<script>
import extraOptions from "@/assets/json/extraoptions.json";
import locations from "@/assets/json/locations.json";
import { mapActions, mapState } from "pinia";
import { useBookingStore } from "~/stores/bookingStore";
import { useCartStore } from "~/stores/cartStore";
export default {
	name: "Cart",
	data() {
		return {
			extraOptions
		};
	},
	computed: {
		...mapState(useCartStore, ["selectedCar", "selectedOptions"]),
		...mapState(useBookingStore, ["selectedBooking"]),

		carCode() {
			return this.selectedCar !== null ? this.selectedCar["@attributes"].code : null;
		},
		carDescription() {
			return this.selectedCar.data !== undefined ? this.selectedCar.data.description : null;
		},
		carImage() {
			return !!this.selectedCar['@attributes'].imageurl ? this.selectedCar['@attributes'].imageurl : "/img/noimage.jpg";
		},
		carPassengers() {
			return this.selectedCar !== null ? this.selectedCar["@attributes"].pax : null;
		},
		carDoors() {
			return this.selectedCar["@attributes"].doors !== undefined ? this.selectedCar["@attributes"].doors : null;
		},
		carFuel() {
			return "A/T";
		},
		carSuitcases() {
			return this.selectedCar !== null ? this.selectedCar["@attributes"].suitcases : null;
		},
		pickupLocation() {
			if (this.selectedBooking != null) {
				return locations.filter(station => station.code === this.selectedBooking.pickup_station)[0];
			} else {
				return null;
			}
		},
		pickupDate() {
			return this.selectedBooking !== null ? this.selectedBooking.date_from : null;
		},
		pickupTime() {
			return this.selectedBooking !== null ? this.selectedBooking.time_from : null;
		},
		dropoffLocation() {
			if (this.selectedBooking != null) {
				return locations.filter(station => station.code === this.selectedBooking.return_station)[0];
			} else {
				return null;
			}
		},
		dropoffDate() {
			return this.selectedBooking !== null ? this.selectedBooking.date_to : null;
		},
		dropoffTime() {
			return this.selectedBooking !== null ? this.selectedBooking.time_to : null;
		},
		carOutOfOffice(){
			if (parseInt(this.selectedCar["@attributes"].outofoffice) == "0")
				return 0

			const outofoffice = this.selectedCar !== null ? this.selectedCar["@attributes"].outofoffice * 0.01 : null;
			return this.$helpers.functions.formatTo2Decimals(outofoffice)
		},
		carPrice() {
			const totalRate = this.selectedCar !== null ? this.selectedCar["@attributes"].totalrate * 0.01 : null;
			return this.$helpers.functions.formatTo2Decimals(totalRate);
		},
		carPriceTotal() {
			const totalRate = this.selectedCar !== null ? this.selectedCar["@attributes"].totalrate * 0.01 + this.extraOptionsTotalCost : null;
			return this.$helpers.functions.formatTo2Decimals(totalRate);
		},
		carAvailability() {
			return !!this.selectedCar['@attributes']?.availability ? this.selectedCar['@attributes']?.availability : ''
		},
		extraOptionsTotalCost() {
			let cost = 0;

			if (this.chosenOptions.length) {
				cost = this.chosenOptions.reduce((total, item) => total + this.getChosenOptionCost(item.code, item.value, false), 0);
			}

			return cost * 0.01;
		},
		chosenOptions() {
			return this.selectedOptions ? this.selectedOptions.filter(item => item.value) : [];
		}
	},
	methods: {
		...mapActions(useCartStore, ["storeSelectedCar", "storeSelectedOptions"]),
		...mapActions(useBookingStore, ["storeSelectedBooking"]),

		getChosenOptionCost(type, value, formatted = true) {
			const option = this.selectedCar.options.option.find(item => item["@attributes"].code == type)["@attributes"];
			const rate = option.rate;

			if (option.inclusive === "true") return formatted ? this.$helpers.functions.formatTo2Decimals(0) : 0;

			// If the value is a positive integer and firstFree is true
			if (!isNaN(parseInt(value)) && parseInt(value) > 0 && option.firstfree === "true") value--;

			let total = 0;
			if (isNaN(parseInt(value))) total = rate * 0.01;

			total = rate * value;

			if (formatted) return this.$helpers.functions.formatTo2Decimals(total / 100);

			return total;
		},

		getChosenOptionName(code) {
			return this.extraOptions.find(item => item.code == code).name;
		},

		//load data from localstorage into each Store
		loadFromStorage() {
			if (process.browser) {
				//Load Cart Store
				let storageCartState = localStorage.getItem("useCartStore");
				if (storageCartState) {
					this.storeSelectedCar(JSON.parse(storageCartState).selectedCar);
					this.storeSelectedOptions(JSON.parse(storageCartState).selectedOptions);
				}

				//Load Booking Store
				let storageBookingState = localStorage.getItem("useBookingStore");
				if (storageBookingState) {
					this.storeSelectedBooking(JSON.parse(storageBookingState).selectedBooking);
				}
			}
		}
	},
	created() {
		this.loadFromStorage();
	}
};
</script>

<style></style>
