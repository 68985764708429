<template>
	<div ref="searchContainer" class="w-full">
		<div v-if="isSearchFormCollapsed && allowSearchFormCollapsed" @click="isSearchFormCollapsed = !isSearchFormCollapsed">
			<div class="font-semibold text-md md:text-lg pb-2 md:pb-0">{{ $t("_searchform.details") }}</div>
			<div class="flex justify-around">
				<div class="w-2/5 h-12 text-xs md:text-sm border-r border-r-slate-200 mr-4 border-0">
					<div class="font-semibold text-gray-500 pb-2 md:pb-0">{{ $t("_searchform.pickup") }}:</div>
					{{ dateFrom }} {{ timeFrom }}
				</div>
				<div class="w-2/5 h-12 text-xs md:text-sm">
					<div class="font-semibold text-gray-500 pb-2 md:pb-0">{{ $t("_searchform.dropoff") }}:</div>
					{{ dateTo }} {{ timeTo }}
				</div>
				<div class="w-1/5 h-12">
					<v-btn class="ma-2 h-full" dark outlined color="darkblue">
						<v-icon>{{ svgPencil }}</v-icon>
					</v-btn>
				</div>
			</div>
		</div>
		<v-form v-else ref="formSearch" v-model="validSearchForm">
			<div class="flex flex-col lg:flex-row items-center justify-between max-w-lg lg:max-w-full mx-auto">
				<!-- Pickup Location, Date, Time -->
				<div class="grid sm:grid-cols-2 gap-2 w-full">
					<div class="flex flex-col lg:flex-row justify-between space-y-2 lg:space-y-0 lg:space-x-3 w-full lg:w-auto">
						<v-autocomplete required v-model="locationFrom" class="mt-0 font-bold" :label="$t('_searchform.pickup_location')" :items="formattedLocations" item-text="title" item-value="value"></v-autocomplete>
						<!-- Pickup Date -->

						<v-menu v-model="menuDateFrom" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field required v-model="dateFrom" class="font-bold" :label="$t('_searchform.pickup_date')" readonly v-bind="attrs" v-on="on"></v-text-field>
							</template>
							<v-date-picker color="bg-primary-500 lighten-1 border-0" v-model="dateFrom" @input="menuDateFrom = false" :min="pickupStartDate" no-title scrollable> </v-date-picker>
						</v-menu>

						<!-- Pickup Time -->
						<div class="relative timer-wrapper">
							<div class="label-time">Time</div>
							<VueTimepicker v-model="timeFrom" format="HH:mm" close-on-complete :hour-range="[[parseInt($config.weekOfficeOpeningTime.substr(0, 2)), parseInt(pickupEndTime.substr(0, 2))]]" :minute-interval="15" class="!scrollbar-hide"></VueTimepicker>
						</div>
					</div>

					<div class="flex flex-col lg:flex-row justify-between space-y-2 lg:space-y-0 lg:space-x-3 lg:border-l-2 lg:border-gray-500 lg:pl-4 lg:w-auto">
						<!-- TODO: Dropoff Location -->

						<v-autocomplete required v-model="locationTo" class="mt-0 font-bold" :label="$t('_searchform.dropoff_location')" :items="formattedLocations" item-text="title" item-value="value"></v-autocomplete>
						<!-- Dropoff Date -->
						<v-menu v-model="menuDateTo" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field required v-model="dateTo" class="font-bold" :label="$t('_searchform.dropoff_date')" readonly v-bind="attrs" v-on="on"></v-text-field>
							</template>
							<v-date-picker color="bg-primary-500 lighten-1" v-model="dateTo" @input="menuDateTo = false" :min="returnStartDate" no-title></v-date-picker>
						</v-menu>

						<!-- Dropoff Time -->
						<div class="relative timer-wrapper">
							<div class="label-time">Time</div>
							<VueTimepicker v-model="timeTo" format="HH:mm" close-on-complete :hour-range="[[parseInt($config.weekOfficeOpeningTime.substr(0, 2)), parseInt(returnEndTime.substr(0, 2))]]" :minute-interval="15"></VueTimepicker>
						</div>
					</div>
				</div>
				<div class="flex-shrink-0 w-full lg:w-auto lg:ml-4">
					<v-btn class="!bg-black !text-white w-full lg:w-auto p-2" @click="submitSearchForm">
						<svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 16.31 16.31">
							<g id="icon_search" transform="translate(0.75 0.75)">
								<circle id="Ellipse_1" data-name="Ellipse 1" cx="6.973" cy="6.973" r="6.973" fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></circle>
								<line id="Line_1" data-name="Line 1" x2="2.479" y2="2.616" transform="translate(12.021 11.884)" fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></line>
							</g>
						</svg>
						<span class="pl-2">{{ $t("_searchform.button") }}</span>
					</v-btn>
				</div>
			</div>
		</v-form>
	</div>
</template>
<style>
textarea:focus,
input:focus {
	outline: none;
}
span.vue__time-picker.time-picker {
	top: -1px;
	width: 100%;
}
.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
	background: #ed4824;
}
.vue__time-picker .dropdown,
.vue__time-picker .dropdown .select-list {
	height: 10.2em;
}

.vue__time-picker .dropdown ul li:not([disabled]):hover {
	color: #ed4824 !important;
}
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
	color: #fff !important;
}
.vue__time-picker .dropdown ul li[disabled] {
	display: none;
}
.time-picker .dropdown.drop-down ::-webkit-scrollbar {
	width: 1px !important;
}

.time-picker .dropdown.drop-down ::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.time-picker .dropdown.drop-down ::-webkit-scrollbar-thumb {
	background-color: #ed4824;
	outline: 1px solid #ed4824;
}
.vue__time-picker.time-picker input {
	width: 100%;
	border: 0;
	border-bottom: 1px solid #949494;
	top: -1px;
	position: relative;
	padding-left: 0;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
.label-time {
	height: 20px;
	line-height: 20px;
	letter-spacing: normal;
	font-size: 16px;
	max-width: 133%;
	transform: translateY(-18px) scale(0.75);
	pointer-events: auto;
	font-weight: bold;
	opacity: 0.6;
	position: absolute;
	left: -5px;
	top: 3px;
}
@media only screen and (max-width: 1023px) {
	.timer-wrapper {
		margin-top: 20px !important;
	}
	span.vue__time-picker.time-picker {
		margin-bottom: 15px;
	}
	.v-input {
		margin-bottom: 10px;
	}
}
</style>
<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

import locations from "@/assets/json/locations.json";
import { mdiPencil } from "@mdi/js";
import { mapActions, mapState } from "pinia";
import { useBookingStore } from "~/stores/bookingStore";
export default {
	props: ["type"],
	name: "SearchForm",
	components: { VueTimepicker },
	data() {
		return {
			svgPencil: mdiPencil,
			isSearchFormCollapsed: true,
			validSearchForm: false,
			menuDateTo: false,
			menuDateFrom: false,
			latePickup: false,
			dateFrom: new Date(Date.now() + 3600 * 1000 * 24 - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
			dateTo: new Date(Date.now() + 3600 * 1000 * 48 - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
			timeFrom: '10:00',
			timeTo: '10:00',
			locationFrom: { station: "MAIN", code: "1" },
			locationTo: { station: "MAIN", code: "1" },
			locations: locations
		};
	},
	watch: {
		dateFrom(newDate, oldDate) {
			if (newDate >= this.dateTo) {
				let date = new Date(newDate);
				date.setDate(date.getDate() + 1);
				this.dateTo = date.toISOString().substr(0, 10);
			}
		},
		pickupStartTime(newTime, oldTime) {
			if (newTime > oldTime) {
				this.timeFrom = this.nextQuarter(newTime);
			}
		}
	},
	computed: {
		...mapState(useBookingStore, ["selectedBooking"]),
		formattedLocations() {
			return this.locations.map(item => {
				return {
					title: this.$i18n.locale == "en" ? item.name : item.name_el,
					value: { station: item.station, code: item.code }
				};
			});
		},

		currentMinutes() {
			return new Date().getMinutes();
		},
		currentHour() {
			return new Date().getHours();
		},
		currentDay() {
			return new Date().getDate();
		},
		currentTime() {
			// in HH:mm format
			let today = new Date();
			return this.getTime(today.getHours(), today.getMinutes());
		},

		// Pickup picker configuration
		pickupStartDate() {
			let today = new Date();
			
			// Sirius special rule
			today.setDate(today.getDate() + 1);
			return today.toISOString().substring(0, 10);

			// After closing time
			if (this.getTime(today.getHours() + 5, today.getMinutes()) > this.getClosingTime(today)) {
				this.latePickup = true;
				return new Date(Date.now() + 3600 * 1000 * 24 * 1 - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
			}

			// Before opening time
			if (this.getTime(today.getHours() + 5, today.getMinutes()) < this.getOpeningTime(today, this.latePickup)) {
				this.latePickup = true;
				let d = new Date();
				d.setDate(d.getDate() + 1);
				return d.toISOString().substr(0, 10);
			}

			// Within working hours
			if (this.getTime(today.getHours() + 5, today.getMinutes()) < this.getClosingTime(today) && this.getTime(today.getHours() + 5, today.getMinutes()) > this.getOpeningTime(today, this.latePickup)) {
				let d = new Date();
				d.setDate(d.getDate());
				return d.toISOString().substr(0, 10);
			}

			return new Date(Date.now() + 3600 * 1000 * 24 * 1 - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
		},
		pickupEndDate() {
			// Max pickup end date | UNUSED DO NOT REMOVE
			return new Date(Date.now() + 3600 * 1000 * 24 - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
		},
		pickupStartTime() {
			return this.$config.weekOfficeOpeningTime

			// If user has selected a date check those
			if (this.dateFrom > this.pickupStartDate) {
				return this.getOpeningTime(new Date(this.dateFrom), false);
			}

			// if we can pick today, set for 5 hours from now
			if (this.pickupStartDate == new Date().toISOString().substr(0, 10)) {
				let date = new Date();
				date.setHours(date.getHours() + 6);
				return this.getTime(date.getHours(), date.getMinutes());
			}

			// If user returned selected to initial date return that opening time
			if (this.dateFrom == this.pickupStartDate) {
				return this.getOpeningTime(new Date(this.dateFrom), this.latePickup);
			}

			if (this.latePickup) return this.getLatePickupTime();

			return this.getOpeningTime(new Date(this.dateFrom), this.latePickup);
		},
		pickupEndTime() {
			return this.getClosingTime(new Date(this.dateFrom));
		},

		// Return picker configuration
		returnStartDate() {
			let date = new Date(this.dateFrom);
			date.setDate(date.getDate() + 1);
			return date.toISOString().substr(0, 10);
		},
		returnEndDate() {
			// Max return end date | UNUSED DO NOT REMOVE
			return new Date(Date.now() + 3600 * 1000 * 48 - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
		},
		returnStartTime() {
			return this.getOpeningTime(new Date(this.dateTo), false);
		},
		returnEndTime() {
			return this.getClosingTime(new Date(this.dateTo));
		},

		//allows the search form to be collapsed in Mobile only
		//works together with isSearchFormCollapsed
		allowSearchFormCollapsed() {
			const allowedRoutes = ["/cars", "/locations", "/search"];
			if (allowedRoutes.includes(this.$route.path.replaceAll("/" + this.$i18n.locale + "/", "/"))) {
				switch (this.$vuetify.breakpoint.name) {
					case "xs":
						return true;
					case "sm":
						return true;
					case "md":
						return true;
					case "lg":
						return true;
					case "xl":
						return false;
				}
			} else return false;
		}
	},
	methods: {
		...mapActions(useBookingStore, ["storeSelectedBooking"]),

		nextAvailableSlot(time) {},
		getTime(hours, minutes) {
			let val = `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}`;
			val = this.nextQuarter(val);
			return val;
		},
		padTo2Digits(num) {
			return num.toString().padStart(2, "0");
		},
		allowedTimeFromHours(allowedHours) {
			return allowedHours;
		},
		allowedTimeToHours(selectedHour) {
			return selectedHour;
		},
		allowedStep: m => m % 15 === 0,
		validate() {
			this.$refs.formSearch.validate();
		},
		submitSearchForm() {
			const validateForm = this.$refs.formSearch.validate();
			if (validateForm && this.validSearchForm) {
				this.searchCars();
				//collapse the search form
				this.isSearchFormCollapsed = true;
			}
		},
		searchCars() {
			this.storeSelectedBooking({
				...this.selectedBooking,
				date_from: this.dateFrom,
				date_to: this.dateTo,
				time_from: this.timeFrom,
				time_to: this.timeTo,
				pickup_station: this.locationFrom.station,
				return_station: this.locationTo.station,
				pickup_point: this.locationFrom.code,
				dropoff_point: this.locationTo.code
			});

			//Proceed to Checkout page
			this.$router.push({
				path: this.localePath("/search"),
				query: {
					pickup_station: this.locationFrom.station,
					pickup_point: this.locationFrom.code,
					date_from: this.dateFrom,
					time_from: this.timeFrom,
					return_station: this.locationTo.station,
					dropoff_point: this.locationTo.code,
					date_to: this.dateTo,
					time_to: this.timeTo
				}
			});
		},
		isEmpty(data) {
			if (typeof data === "string") {
				return data === "" ? true : false;
			}
			if (typeof data === "object") {
				return !Boolean(Object.keys(data).length);
			}
			if (data.isArray) {
				return !Boolean(data.length);
			}
		},
		checkForm() {
			if (this.isEmpty(this.locationFrom)) {
				this.$refs.searchContainer.classList.add("error");
				return;
			}
			if (this.isEmpty(this.dateFrom)) {
				this.$refs.searchContainer.classList.add("error");
				return;
			}
			if (this.isEmpty(this.timeFrom)) {
				this.$refs.searchContainer.classList.add("error");
				return;
			}
			if (this.isEmpty(this.dateTo)) {
				this.$refs.searchContainer.classList.add("error");
				return;
			}
			if (this.isEmpty(this.timeTo)) {
				this.$refs.searchContainer.classList.add("error");
				return;
			}
			this.$emit("action", "close");
			this.searchCars();
		},
		reset() {
			this.$refs.searchContainer.classList.remove("error");
		},
		loadFromStorage() {
			if (process.browser) {
				let storageBookingState = localStorage.getItem("useBookingStore");
				if (storageBookingState) {
					this.storeSelectedBooking(JSON.parse(storageBookingState).selectedBooking);

					// Pickup data
					if (this.selectedBooking.date_from >= this.dateFrom) this.dateFrom = this.selectedBooking.date_from;

					if (this.selectedBooking.time_from >= this.timeFrom) this.timeFrom = this.selectedBooking.time_from;

					if (this.selectedBooking.pickup_station && this.selectedBooking.pickup_point) {
						this.locationFrom = {
							station: this.selectedBooking.pickup_station,
							code: this.selectedBooking.pickup_point
						};
					}

					if (this.selectedBooking.pickup_point) this.locationFrom.code = this.selectedBooking.pickup_point;

					// Return data
					if (this.selectedBooking.date_to >= this.dateTo) this.dateTo = this.selectedBooking.date_to;

					if (this.selectedBooking.time_to >= this.timeTo) this.timeTo = this.selectedBooking.time_to;

					if (this.selectedBooking.return_station && this.selectedBooking.dropoff_point) {
						this.locationTo = {
							station: this.selectedBooking.return_station,
							code: this.selectedBooking.dropoff_point
						};
					}
				}
			}
		},
		getBookingDetails() {
			// Order of importance is:
			// 1. query parameters
			// 2. booking store
			// 3. localStorage

			// populate form from the query parameters
			if (!this.isEmpty(this.$route.query)) {
				if (this.$route.query.pickup_station && this.$route.query.pickup_point) {
					this.locationFrom = {
						station: this.$route.query.pickup_station,
						code: this.$route.query.pickup_point
					};
				}
				if (this.$route.query.date_from) {
					this.dateFrom = this.$route.query.date_from;
				}
				if (this.$route.query.time_from) {
					this.timeFrom = this.nextQuarter(this.$route.query.time_from);
				}

				if (this.$route.query.return_station && this.$route.query.dropoff_point) {
					this.locationTo = {
						station: this.$route.query.return_station,
						code: this.$route.query.dropoff_point
					};
				}
				if (this.$route.query.date_from) {
					this.dateTo = this.$route.query.date_to;
				}
				if (this.$route.query.time_from) {
					this.timeTo = this.$route.query.time_to;
				}

				const query = this.$route.query;
				const booking = {
					pickup_station: query.pickup_station,
					return_station: query.return_station,
					pickup_point: query.pickup_point,
					dropoff_point: query.dropoff_point,
					date_from: query.date_from,
					date_to: query.date_to,
					time_from: query.time_from,
					time_to: query.time_to
				};
				this.storeSelectedBooking({ ...booking, ...this.selectedBooking });
				return;
			}

			// populate form from the store
			if (this.selectedBooking != null) {
				this.timeFrom = this.nextQuarter(this.selectedBooking.time_from);
				this.timeTo = this.selectedBooking.time_to;
				this.dateFrom = this.selectedBooking.date_from;
				this.dateTo = this.selectedBooking.date_to;
				return;
			}

			// populate form from the localStorage
			if (localStorage.getItem("useBookingStore")) {
				this.loadFromStorage();
				return;
			}

			// fallback to the default
			// round to next quarter

			this.timeFrom = this.nextQuarter('10:00');
			this.timeTo = this.nextQuarter('11:00');
			this.dateFrom = this.pickupStartDate;
			this.dateTo = this.returnStartDate;
		},
		isWeekend(day) {
			return day.getDay() === 6 || day.getDay() === 0;
		},
		nextQuarter(time) {
			const pickupDateParts = time.split(":");
			const newtime = pickupDateParts[0] + ":" + this.padTo2Digits((Math.ceil(pickupDateParts[1] / 15) * 15) % 60).toString();

			if (newtime > this.getClosingTime) return this.getClosingTime;

			return newtime;
		},
		getOpeningTime(date = new Date(), latePickup) {
			if (!(date instanceof Date) || !isFinite(date)) date = new Date();

			if (latePickup) return this.$config.latePickup;

			if (this.isWeekend(date)) {
				return this.$config.weekendOfficeOpeningTime;
			}
			return this.$config.weekOfficeOpeningTime;
		},
		getClosingTime(date = new Date()) {
			if (this.isWeekend(date)) {
				return this.$config.weekendOfficeClosingTime;
			}
			return this.$config.weekOfficeClosingTime;
		},
		getLatePickupTime() {
			return "12:00";
		}
	},

	mounted() {
		this.getBookingDetails();
	}
};
</script>
