<template>
	<nav class="inner_nav car-navbar-yellow sm:flex py-4 items-center text-white">
		<div class="w-full max-w-screen-2xl px-3 sm:px-6 lg:px-8">
			<div class="relative flex items-center justify-between">
				<div class="flex-1 flex items-center justify-start">
					<nuxt-link :to="localePath('/')" class="flex-shrink-0 flex items-center lg:hidden">
						<img class="logo-header h-8 md:h-10 w-auto" src="~/assets/img/logo_sirius-white-star.svg" alt="Logo Header" />
					</nuxt-link>
					<div class="hidden lg:block sm:ml-9 lg:ml-0">
						<div class="navbar-yellow-menu flex space-x-2 lg:space-x-4 border-l border-white pl-6 lg:border-none lg:pl-0">
							<nuxt-link :to="localePath('/vehicles')" class="px-3 py-2 text-sm uppercase font-medium transition duration-200 truncate !text-white hover:!text-warning">{{ $t("_navbarmain.vehicles") }}</nuxt-link>
							<nuxt-link :to="localePath('/locations')" class="px-3 py-2 text-sm uppercase font-medium transition duration-200 truncate !text-white hover:!text-warning">{{ $t("_navbarmain.locations") }}</nuxt-link>
							<nuxt-link :to="localePath('/about')" class="px-3 py-2 text-sm uppercase font-medium transition duration-200 truncate !text-white hover:!text-warning">{{ $t("_navbarmain.about") }}</nuxt-link>
							<nuxt-link :to="localePath('/contact')" class="px-3 py-2 text-sm uppercase font-medium transition duration-200 truncate !text-white hover:!text-warning">{{ $t("_navbarmain.contact") }}</nuxt-link>
						</div>
					</div>
				</div>
				<div class="flex items-center justify-end pr-2 sm:ml-6 sm:pr-0">
					<div class="ml-3 relative">
						<NavMenu class="burger-nav menu-button" />
					</div>
					<div class="nav_rc p-2 lg:flex lg:absolute items-center text-white">
						<LanguageSwitcher class="hidden lg:block" />
						<ProfileAvatar class="hidden lg:block pl-3 border-white border-l-2" />
					</div>
				</div>
			</div>
		</div>
	</nav>
</template>
<style>
.inner_nav {
	background: black;
}
.nav_rc {
	display: flex;
}
.burger-nav {
	display: none;
}
@media screen and (max-width: 1263px) {
	.inner_nav {
		background: #ed4824;
	}
	.nav_rc {
		display: none;
	}
	.burger-nav {
		display: block;
	}
}
</style>
<script>
import LanguageSwitcher from "~/components/LanguageSwitcher.vue";
import NavMenu from "~/components/nav/NavMenu";
import ProfileAvatar from "~/components/profileAvatar.vue";

export default {
	components: {
		NavMenu,
		LanguageSwitcher,
		ProfileAvatar
	}
};
</script>
