import clientAPI from './AxiosConfig';

export default function (baseURL) {

  return {
    async getPosts(params) {
      return await clientAPI({ url: baseURL })
        //.get('/posts?_embed&_fields=_embedded, id,excerpt,title,guid,link,featured_media_src,featured_media', { params })
        .get('/posts?_embed', { params })
        .then(res=> res.data)
        .catch(err => err)
    },
  
    async getPost(params) {
      return await clientAPI({ url: baseURL})
        .get('/posts?_fields=author,id,excerpt,title,content,link', { params })
        //.then(res=> res.data)
        .catch(err => err)
    }
  }

};