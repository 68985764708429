function MyPiniaPlugin({ store }) {
  // React to changes below
  store.$subscribe((mutation, state) => {
    localStorage.setItem(mutation.storeId, JSON.stringify(state))
  },{ detached: true })

}

const myPlugin = ({ $pinia }) => {
  $pinia.use(MyPiniaPlugin)
}

export default myPlugin