<template>
	<div class="flex-1 pb-12 relative overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-400">
		<div class="mt-4 flex space-x-4">
			<div class="flex-1 relative px-5 flex flex-col justify-center">
				<div class="text-white mb-3 font-bold"><span v-html="$t('_sidebar_locations.search_title')"></span></div>
				<div>
					<div class="absolute inset-y-0 left-4 top-9 flex items-center pl-3 pointer-events-none">
						<svg xmlns="http://www.w3.org/2000/svg" width="16.31" height="16.31" viewBox="0 0 16.31 16.31" class="text-[#C4C4C4]">
							<g id="icon_search" transform="translate(0.75 0.75)">
								<circle id="Ellipse_1" data-name="Ellipse 1" cx="6.973" cy="6.973" r="6.973" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
								<line id="Line_1" data-name="Line 1" x2="2.479" y2="2.616" transform="translate(12.021 11.884)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
							</g>
						</svg>
					</div>
					<input v-model="search" class="w-full bg-white text-black placeholder:text-sm py-2 pr-2 pl-7" type="text" name="search" id="searchField" :placeholder="$t('_sidebar_locations.search')" />
				</div>
			</div>
		</div>
		<div class="h-6 bg-black w-full mt-4"></div>
		<v-layout row>
			<v-flex>
				<v-list color="transparent" one-line class="sidebar-location-list p-4 space-y-1 flex flex-col">
					<template v-for="(item, index) in filteredItems">
						<v-list-item :key="`loc_${item.code}`" ripple @click="toggle(item.code)" :class="{ is_loc_active: item.code == selectedLocation }">
							<v-list-item-content>
								<v-list-item-title class="text-white font-medium hover:text-white transition">{{ $i18n.locale == "en" ? item.name : item.name_el }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider v-if="index + 1 < filteredItems.length" :key="index"></v-divider>
					</template>
				</v-list>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import locations from "@/assets/json/locations.json";
import { mdiMagnify } from "@mdi/js";
import { mapActions, mapState } from "pinia";
import { useLocationStore } from "~/stores/locationStore";
export default {
	name: "SidebarLocations",
	data() {
		return {
			isOpenLocationDrawer: true,
			svgMagnify: mdiMagnify,
			locations: locations,
			selected: [1],
			search: ""
		};
	},

	computed: {
		...mapState(useLocationStore, ["selectedLocation"]),
		filteredItems() {
			return this.locations.filter(item => {
				if (!this.search) return true;

				if (this.$i18n.locale == "en") {
					return item.name.toLowerCase().includes(this.search.toLowerCase());
				} else {
					return item.name_el.toLowerCase().includes(this.search.toLowerCase());
				}
			});
		},
		formattedLocations() {
			return this.locations.map(item => {
				return {
					title: this.$i18n.locale == "en" ? item.name : item.name_el,
					value: { station: item.station, code: item.code }
				};
			});
		}
	},
	methods: {
		...mapActions(useLocationStore, ["storeSelectedLocation", "centerMapToLocation"]),
		clearSearch() {
			this.search = "";
		},
		toggle(locationCode) {
			const i = this.selected.indexOf(locationCode);
			//store the clicked Location to center Google Maps
			this.storeSelectedLocation(locationCode);
		}
	}
};
</script>
<style lang="scss">
.is_loc_active {
	background-color: #003a69;
}
</style>
