import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5894551c = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _7cd7c672 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _45f6f74a = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _4dc26c7c = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _a106494c = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _1aa8bcf5 = () => interopDefault(import('../pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _3d6810e8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7fa67850 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _536abf74 = () => interopDefault(import('../pages/forgotpassword.vue' /* webpackChunkName: "pages/forgotpassword" */))
const _7edaaa8f = () => interopDefault(import('../pages/leases.vue' /* webpackChunkName: "pages/leases" */))
const _09590978 = () => interopDefault(import('../pages/licences.vue' /* webpackChunkName: "pages/licences" */))
const _73cf9f18 = () => interopDefault(import('../pages/locations.vue' /* webpackChunkName: "pages/locations" */))
const _aa18caba = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _245a834d = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _fe2a76b4 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _de6d514e = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _c6f80446 = () => interopDefault(import('../pages/reservations.vue' /* webpackChunkName: "pages/reservations" */))
const _01b19456 = () => interopDefault(import('../pages/reviews.vue' /* webpackChunkName: "pages/reviews" */))
const _8344493e = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _84f529d2 = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _166d24d9 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _e96c947e = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _6528bcab = () => interopDefault(import('../pages/thankyou.vue' /* webpackChunkName: "pages/thankyou" */))
const _7a66fb80 = () => interopDefault(import('../pages/vehicles/index.vue' /* webpackChunkName: "pages/vehicles/index" */))
const _a619c0fa = () => interopDefault(import('../pages/profile/reservations.vue' /* webpackChunkName: "pages/profile/reservations" */))
const _496439da = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _88626c62 = () => interopDefault(import('../pages/services/_slug.vue' /* webpackChunkName: "pages/services/_slug" */))
const _7dd43e10 = () => interopDefault(import('../pages/vehicles/_slug.vue' /* webpackChunkName: "pages/vehicles/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _5894551c,
    name: "404___en"
  }, {
    path: "/about",
    component: _7cd7c672,
    name: "about___en"
  }, {
    path: "/blog",
    component: _45f6f74a,
    name: "blog___en"
  }, {
    path: "/checkout",
    component: _4dc26c7c,
    name: "checkout___en"
  }, {
    path: "/contact",
    component: _a106494c,
    name: "contact___en"
  }, {
    path: "/cookie-policy",
    component: _1aa8bcf5,
    name: "cookie-policy___en"
  }, {
    path: "/el",
    component: _3d6810e8,
    name: "index___el"
  }, {
    path: "/faq",
    component: _7fa67850,
    name: "faq___en"
  }, {
    path: "/forgotpassword",
    component: _536abf74,
    name: "forgotpassword___en"
  }, {
    path: "/leases",
    component: _7edaaa8f,
    name: "leases___en"
  }, {
    path: "/licences",
    component: _09590978,
    name: "licences___en"
  }, {
    path: "/locations",
    component: _73cf9f18,
    name: "locations___en"
  }, {
    path: "/login",
    component: _aa18caba,
    name: "login___en"
  }, {
    path: "/privacy-policy",
    component: _245a834d,
    name: "privacy-policy___en"
  }, {
    path: "/profile",
    component: _fe2a76b4,
    name: "profile___en"
  }, {
    path: "/register",
    component: _de6d514e,
    name: "register___en"
  }, {
    path: "/reservations",
    component: _c6f80446,
    name: "reservations___en"
  }, {
    path: "/reviews",
    component: _01b19456,
    name: "reviews___en"
  }, {
    path: "/search",
    component: _8344493e,
    name: "search___en"
  }, {
    path: "/services",
    component: _84f529d2,
    name: "services___en"
  }, {
    path: "/settings",
    component: _166d24d9,
    name: "settings___en"
  }, {
    path: "/terms",
    component: _e96c947e,
    name: "terms___en"
  }, {
    path: "/thankyou",
    component: _6528bcab,
    name: "thankyou___en"
  }, {
    path: "/vehicles",
    component: _7a66fb80,
    name: "vehicles___en"
  }, {
    path: "/el/404",
    component: _5894551c,
    name: "404___el"
  }, {
    path: "/el/about",
    component: _7cd7c672,
    name: "about___el"
  }, {
    path: "/el/blog",
    component: _45f6f74a,
    name: "blog___el"
  }, {
    path: "/el/checkout",
    component: _4dc26c7c,
    name: "checkout___el"
  }, {
    path: "/el/contact",
    component: _a106494c,
    name: "contact___el"
  }, {
    path: "/el/cookie-policy",
    component: _1aa8bcf5,
    name: "cookie-policy___el"
  }, {
    path: "/el/faq",
    component: _7fa67850,
    name: "faq___el"
  }, {
    path: "/el/forgotpassword",
    component: _536abf74,
    name: "forgotpassword___el"
  }, {
    path: "/el/leases",
    component: _7edaaa8f,
    name: "leases___el"
  }, {
    path: "/el/licences",
    component: _09590978,
    name: "licences___el"
  }, {
    path: "/el/locations",
    component: _73cf9f18,
    name: "locations___el"
  }, {
    path: "/el/login",
    component: _aa18caba,
    name: "login___el"
  }, {
    path: "/el/privacy-policy",
    component: _245a834d,
    name: "privacy-policy___el"
  }, {
    path: "/el/profile",
    component: _fe2a76b4,
    name: "profile___el"
  }, {
    path: "/el/register",
    component: _de6d514e,
    name: "register___el"
  }, {
    path: "/el/reservations",
    component: _c6f80446,
    name: "reservations___el"
  }, {
    path: "/el/reviews",
    component: _01b19456,
    name: "reviews___el"
  }, {
    path: "/el/search",
    component: _8344493e,
    name: "search___el"
  }, {
    path: "/el/services",
    component: _84f529d2,
    name: "services___el"
  }, {
    path: "/el/settings",
    component: _166d24d9,
    name: "settings___el"
  }, {
    path: "/el/terms",
    component: _e96c947e,
    name: "terms___el"
  }, {
    path: "/el/thankyou",
    component: _6528bcab,
    name: "thankyou___el"
  }, {
    path: "/el/vehicles",
    component: _7a66fb80,
    name: "vehicles___el"
  }, {
    path: "/profile/reservations",
    component: _a619c0fa,
    name: "profile-reservations___en"
  }, {
    path: "/el/profile/reservations",
    component: _a619c0fa,
    name: "profile-reservations___el"
  }, {
    path: "/el/blog/:slug",
    component: _496439da,
    name: "blog-slug___el"
  }, {
    path: "/el/services/:slug",
    component: _88626c62,
    name: "services-slug___el"
  }, {
    path: "/el/vehicles/:slug",
    component: _7dd43e10,
    name: "vehicles-slug___el"
  }, {
    path: "/blog/:slug",
    component: _496439da,
    name: "blog-slug___en"
  }, {
    path: "/services/:slug",
    component: _88626c62,
    name: "services-slug___en"
  }, {
    path: "/vehicles/:slug",
    component: _7dd43e10,
    name: "vehicles-slug___en"
  }, {
    path: "/",
    component: _3d6810e8,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
