<template>
	<div>
		<v-btn depressed class="lg:hidden menu-button text-capitalize d-none" @click="dialog = true" aria-label="Menu">
			<v-icon left class="menu-button-icon" size="23" aria-hidden="true">{{ svgMenu }}</v-icon>
			<span class="menu-button-name d-none d-lg-flex">Menu</span>
		</v-btn>
		<v-btn icon depressed class="menu-button d-lg-none" @click="dialog = true" aria-label="Main Menu">
			<v-icon class="menu-button-icon-small" size="23" aria-hidden="true">{{ svgMenu }}</v-icon>
		</v-btn>
		<v-dialog fullscreen v-model="dialog">
			<div class="bg-white h-screen">
				<div>
					<v-row class="px-6 pt-6 pb-4 flex items-center mx-auto bg-primary-500">
						<nuxt-link to="/">
							<img src="~/assets/img/logo_sirius-white-star.svg" alt="Cars Logo" @click="dialog = false" preload />
						</nuxt-link>
						<v-spacer></v-spacer>
						<!-- mobile x icon -->
						<v-btn icon class="close-button justify-end text-white" @click="dialog = false">
							<svg xmlns="http://www.w3.org/2000/svg" width="14.121" height="14.121" viewBox="0 0 14.121 14.121">
								<g id="icon_times" transform="translate(1.061 1.061)">
									<g id="Group_3005" data-name="Group 3005">
										<line id="Line_385" data-name="Line 385" x2="12" y2="12" transform="translate(0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5" />
										<line id="Line_386" data-name="Line 386" x1="12" y2="12" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1.5" />
									</g>
								</g>
							</svg>
						</v-btn>
					</v-row>
					<v-row class="pt-9 px-6">
						<v-col class="px-1" cols="12" v-for="(category, i) in categories" :key="i">
							<div class="menu-heading-div">
								<v-icon size="35" color="#f5c518">{{ category.icon }}</v-icon>
								<div class="category-heading" @click="dialog = false">
									<nuxt-link :to="localePath(category.link)" class="item-link">
										{{ $t(category.name) }}
									</nuxt-link>
								</div>
							</div>
							<v-list v-if="category.items" color="darkblue">
								<v-list-item v-for="(item, i) in category.items" :key="i" class="list-item ml-4">
									<nuxt-link :to="item.link" class="item-link">
										<v-list-item-title @click="dialog = false" class="color:">
											{{ item.heading }}
										</v-list-item-title>
									</nuxt-link>
								</v-list-item>
								<v-list-item class="list-item ml-4">
									<nuxt-link :to="localePath('/profile')" class="item-link">
										<v-list-item-title @click="dialog = false" class="color:"> My Profile </v-list-item-title>
									</nuxt-link>
								</v-list-item>
							</v-list>
						</v-col>
						<v-col class="cols-12 pl-1">
							<div class="">
								<div @click="dialog = false">
									<nuxt-link class="gap-2 flex items-center item-link" :to="localePath('/profile')">
										<div>My Profile</div>

										<v-icon color="white">{{ avatar }}</v-icon>
									</nuxt-link>
								</div>
							</div>
						</v-col>
					</v-row>
				</div>
			</div>
		</v-dialog>
	</div>
</template>

<script>
import { mdiAccountCircleOutline, mdiClose, mdiMenu } from "@mdi/js";
export default {
	name: "NavMenu",
	data() {
		return {
			svgClose: mdiClose,
			svgMenu: mdiMenu,
			dialog: false,
			avatar: mdiAccountCircleOutline,
			categories: [
				{
					name: "_navbarmain.services",
					link: "/services"
				},
				{
					name: "_navbarmain.vehicles",
					link: "/vehicles"
				},
				{
					name: "_navbarmain.locations",
					link: "/locations"
				},
				{
					name: "_navbarmain.about",
					link: "/about"
				},
				{
					name: "_navbarmain.contact",
					link: "/contact"
				},
				{
					name: "_footer.cookies",
					link: "/cookie-policy"
				},
				{
					name: "_footer.terms",
					link: "/terms"
				},
				{
					name: "_footer.privacy_policy",
					link: "/privacy-policy"
				}
			]
		};
	}
};
</script>

<style scoped>
.menu-button {
	background-color: transparent !important;
	color: white;
}

.menu-button-icon {
	color: grey !important;
}

.menu-button-icon-small {
	color: white !important;
}

.menu-button-name {
	font-weight: 450;
	font-size: 1.1em;
	letter-spacing: 0.48px;
}

.close-button {
	background-color: #ed4824;
	width: 45px;
	height: 45px;
}

.close-button:hover {
	opacity: 0.8;
}

.menu-heading-div {
	display: flex;
	justify-content: left;
}
/* 
.category-heading {
	padding-left: 16px;
} */

.list-item {
	justify-content: left;
	list-style: none;
}

.item-link {
	text-transform: uppercase;
	text-decoration: none;
	color: white;
	font-weight: 550;
}

.item-link:hover {
	text-decoration: underline;
}

@media screen and (max-width: 1024px) {
	.item-link {
		color: black;
	}
	.v-application .white--text {
		color: black !important;
	}
}
</style>
