import { defineStore } from "pinia";

export const useBookingStore = defineStore('useBookingStore', {
    state: () => ({
        selectedBooking: null,
    }),
    actions: {
        storeSelectedBooking(booking) {
            this.selectedBooking = booking
        },
    }
})