import { defineStore } from "pinia";

export const useLocationStore = defineStore('useLocationStore', {
    state: () => ({
        allLocations: null, //all locations from API stored
        allStations: null, //all stations from API stored
        selectedLocation: null,
    }),
    actions: {
        storeSelectedLocation(location) {
            this.selectedLocation = location
        },
        //save all locations from Wheels API
        storeLocations(locations) {
            this.allLocations = locations
        },
        //make Gmaps center to location
        centerMapToLocation(location) {
            this.selectedLocation = location
        },
    }
})