<template>
	<v-app id="app">
		<div class="antialiased bg-gray-100 font-roboto pb-16 md:pb-0">
			<v-navigation-drawer id="nav2" :width="240" style="z-index: 1" v-model="isOpenLocationDrawer" class="pb-0 w-80" fixed app touchless>
				<aside style="width: 240px" class="block bg-primary-500 fixed top-0 inset-y-0 z-10">
					<div class="border-b-2 h-[85px] mx-4 flex items-center justify-center border-black">
						<nuxt-link :to="localePath('/')">
							<img src="~/assets/img/logo_sirius-white-star.svg" class="h-8 md:h-10 w-auto" alt="" />
						</nuxt-link>
					</div>

					<div class="flex-1 overflow-y-scroll h-screen scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-400">
						<SidebarLocations />
					</div>
				</aside>
			</v-navigation-drawer>
			<main class="lg:pl-60 vehicles-container bg-gray-100 min-h-screen relative flex flex-col">
				<NavBarSecondary />
				<div class="xl:block bg-white pt-8 pb-5 px-6 w-full rounded-none shadow-sm">
					<SearchForm />
				</div>
				<Nuxt />
				<v-btn @click.stop="isOpenLocationDrawer = !isOpenLocationDrawer" class="xl:hidden bg-primary-500" color="darkblue" dark fixed bottom right fab>
					<v-icon v-if="isOpenLocationDrawer">{{ svgClose }}</v-icon>
					<v-icon v-else>{{ svgTune }}</v-icon>
				</v-btn>
				<Footer />
			</main>
		</div>
	</v-app>
</template>

<script>
//import locationsQuery from "@/apollo/queries/locations";
import SearchForm from "@/components/SearchForm";
import SidebarLocations from "@/components/SidebarLocations";
import { mdiClose, mdiTune } from "@mdi/js";
import Footer from "~/components/Footer";
import NavBarSecondary from "~/components/nav/NavBarSecondary";
export default {
	name: "CheckoutLayout",
	transition: {
		name: "page",
		mode: "out-in"
	},
	components: {
		NavBarSecondary,
		SearchForm,
		Footer,
		SidebarLocations
	},
	data() {
		return {
			isOpenLocationDrawer: true,
			svgTune: mdiTune,
			svgClose: mdiClose
		};
	}
	/* 	methods: {
		async getLocations() {
			const locationsPosts = await this.$apollo.queries.locations.refetch();
			console.log(locationsPosts.data.locations.nodes);
		}
	},
	apollo: {
		locations: {
			prefetch: false,
			query: locationsQuery
		}
	},
	mounted() {
		this.getLocations();
	} */
};
</script>
