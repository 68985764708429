import clientAPI from './AxiosConfig';

export default function (baseURL, wpURL, wheelsAuth) {

  return {

    async submitContactForm(postdata) {
      return await clientAPI({ url: baseURL, headers: { Authorization: wheelsAuth } })
        .post('/submitContactForm', postdata)
        .catch(err => err)
    },

    async getCars(params) {
      return await clientAPI({ url: baseURL, headers: { Authorization: wheelsAuth } })
        .get('/search', { params })
        .catch(err => err)
    },
  
    async getOrders(userId) {
      return await clientAPI({ url: baseURL, headers: { Authorization: wheelsAuth } })
        .get('/orders/'+userId)
        .catch(err => err)
    },
  
    async createOrder(postdata) {
      return await clientAPI({ url: baseURL, headers: { Authorization: wheelsAuth } })
        .post('/createOrder', postdata)
        .catch(err => err)
    },
  
    async resetPassword(postdata) {
      return await clientAPI({ url: baseURL, headers: { Authorization: wheelsAuth } })
        .post('/resetPassword', postdata)
        .catch(err => err)
    },
  
    async updateOrder(postData) {
      return await clientAPI({ url: baseURL, headers: { Authorization: wheelsAuth } })
        .put('/updateOrder', postData)
        .catch(err => err)
    },
  
    async updateUserData(token, data) {
      return await clientAPI({ url: wpURL })
        .put('/users/me/' + '?jwt=' + token, data)
        .catch(err => err)
    },
  
    async getLocations(params) {
      //check if locations exists else fetch from API
      return await clientAPI({ url: baseURL, headers: { Authorization: wheelsAuth } })
        .get('/locations', { params })
        .catch(err => err)
    }

  }
  
};