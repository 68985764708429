<template>
	<div class="px-4 pt-4 flex flex-col justify-between h-screen">
		<div class="w-full">
			<nuxt-link :to="localePath('/profile')" class="flex items-center h-10 px-3 text-sm mx-auto !text-white hover:!text-gray-500 hover:bg-black !rounded-lg">
				<v-icon color="white" class="mr-[14px]">{{ svgCalendar }}</v-icon>
				<span>{{ $t("profile_sidebar.profile") }}</span>
			</nuxt-link>
			<nuxt-link :to="localePath('/profile/reservations')" class="flex items-center h-10 px-3 text-sm mx-auto !text-white hover:!text-gray-500 !rounded-lg">
				<svg xmlns="http://www.w3.org/2000/svg" width="17.419" height="15.422" viewBox="0 0 17.419 15.422" class="mr-[14px]">
					<g id="icon_sliders-h-circle" transform="translate(0.75 0.75)">
						<line id="Line_226" data-name="Line 226" x2="3.387" transform="translate(0 2.447)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
						<line id="Line_227" data-name="Line 227" x2="2.633" transform="translate(13.286 11.613)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
						<ellipse id="Ellipse_138" data-name="Ellipse 138" cx="2.446" cy="2.445" rx="2.446" ry="2.445" transform="translate(8.22 9.031)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
						<ellipse id="Ellipse_139" data-name="Ellipse 139" cx="2.446" cy="2.445" rx="2.446" ry="2.445" transform="translate(3.785)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
						<line id="Line_336" data-name="Line 336" x2="6.801" transform="translate(9.118 2.447)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
						<line id="Line_337" data-name="Line 337" x2="8.076" transform="translate(0 11.613)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
					</g>
				</svg>
				<span>{{ $t("profile_sidebar.reservations") }}</span>
			</nuxt-link>
		</div>
		<div class="logout_btn w-full px-4">
			<button @click="logout()" class="text-white">{{ $t("profile.logout") }}</button>
		</div>
	</div>
</template>
<style>
.logout_btn {
	position: relative;
	top: -110px;
	padding-top: 20px;
	border-top: 1px solid #e8e8e861;
}
</style>
<script>
import { mdiCalendarCheckOutline } from "@mdi/js";

export default {
	data() {
		return {
			svgCalendar: mdiCalendarCheckOutline
		};
	},
	methods: {
		async logout() {
			try {
				const res = await this.$services.auth.logout(this.$apollo).then(res => res.data);
				if( !!res.logout?.status && res.logout?.status == 'SUCCESS')
					this.$router.push(this.localePath('/'))
				else 
					throw 'Something went wrong'
			} catch (e) {
				this.errors = true
			}
		}
	}
};
</script>

<style scoped>
a.nuxt-link-exact-active {
	@apply bg-black;
}
</style>
