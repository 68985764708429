import { defineStore } from "pinia";

export const useCartStore = defineStore('useCartStore', {
    state: () => ({
        selectedCar: null,
        selectedOptions: null,
    }),
    actions: {
        storeSelectedCar(car) {
            this.selectedCar = car
        },
        storeSelectedOptions(options) {
            this.selectedOptions = options
        },
    }
})