<template>
	<div class="mt-4 px-4">
		<v-expansion-panels v-model="panels" flat multiple>
			<v-expansion-panel flat style="background-color: transparent" class="py-2 rounded-0 border-t border-t-gray-400 first-of-type:!border-transparent" v-for="(type, key) in initialFilters" :key="key">
				<!-- Hide Show Filter Button -->
				<v-expansion-panel-header class="p-0">
					<template v-slot:actions>
						<v-icon color="black" v-if="panels.includes(key)">{{ svgMinus }}</v-icon>
						<v-icon color="black" v-else>{{ svgPlus }}</v-icon>
					</template>

					<div @click="type.show = !type.show" role="button" class="flex justify-between items-center cursor-pointer">
						<h4 class="text-white text-sm font-medium">{{ $t(`_json_filters.${type.name.toLowerCase()}`) }}</h4>
					</div>
				</v-expansion-panel-header>

				<v-expansion-panel-content class="p-0">
					<div v-for="(value, k) in type.value" :key="k">
						<label :for="`${value.name}_${value.code}`" class="pt-1 checkbox-label cursor-pointer text-white text-sm">
							<input type="checkbox" :id="`${value.name}_${value.code}`" :data-id="`${value.name}_${value.code}`" :data-code="value.code" :data-position="type.position" @click="saveSelectedFilters" />
							<span class="pl-2 text-sm">{{ $t("filters." + type.name + "." + value.code) }}</span>
						</label>
					</div>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
	</div>
</template>

<script>
import { mdiMinus, mdiPlus } from "@mdi/js";
import { mapActions, mapState } from "pinia";
import { useGlobalStore } from "~/stores/globalStore";

export default {
	name: "Filters",
	computed: {
		...mapState(useGlobalStore, ["searchResults", "initialFilters", "selectedFilters"])
	},
	data() {
		return {
			panels: [],
			svgPlus: mdiPlus,
			svgMinus: mdiMinus
		};
	},
	methods: {
		...mapActions(useGlobalStore, ["storeInitialFilters", "addSelectedFilter", "removeSelectedFilter"]),

		saveSelectedFilters(item) {
			let checked = item.target.checked;
			let id = item.target.getAttribute("data-id");
			let code = item.target.getAttribute("data-code");
			let position = item.target.getAttribute("data-position");

			if (checked) this.addSelectedFilter(id, { code, position });
			else this.removeSelectedFilter({ code, position });
		}
	}
};
</script>
<style>
.checkbox-label [type="checkbox"] {
	--tw-border-opacity: 1;
	--tw-text-opacity: 1;
	--tw-ring-color: rgb(255 203 5 / var(--tw-ring-opacity));
	--tw-ring-opacity: 0.25;
	--tw-ring-offset-width: 0px;
	background-color: transparent;
	border-color: rgb(0 0 0 / var(--tw-border-opacity));
	border-radius: 0.25rem;
	border-style: solid;
	border-width: 1px;
	color: rgb(0 0 0 / var(--tw-text-opacity));
	cursor: pointer;
	print-color-adjust: exact;
	--tw-shadow: 0 0 #0000;
	-webkit-appearance: none;
	appearance: none;
	background-origin: border-box;
	display: inline-block;
	flex-shrink: 0;
	height: 1rem;
	padding: 0;
	-webkit-user-select: none;
	user-select: none;
	vertical-align: middle;
	width: 1rem;
}

.checkbox-label [type="checkbox"]:checked,
.checkbox-label [type="checkbox"]:checked:focus,
.checkbox-label [type="checkbox"]:checked:hover {
	background-color: currentColor;
	border-color: transparent;
}
.checkbox-label [type="checkbox"]:checked {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 100% 100%;
}
</style>
