import { defineStore } from "pinia";

export const useGlobalStore = defineStore('useGlobalStore', {
    state: () => ({
        priceQuote: {},
        searchResults: [],
        allFilters: [],
        allCars: [],
        initialFilters: [],
        selectedFilters: [],
    }),
    actions: {
        storeQuote(quote) {
            this.priceQuote = quote
        },
        storeResults(results) {
            this.searchResults = results
        },
        storeAllFilters(filters) {
            this.allFilters = filters
        },
        storeAllCars(cars) {
            this.allCars = cars
        },
        storeInitialFilters(filters) {
            this.initialFilters = filters
        },
        addSelectedFilter(id, value) {
            this.selectedFilters = [...this.selectedFilters, value]
        },
        removeSelectedFilter(value) {
            this.selectedFilters = this.selectedFilters.filter((item) => {
                if (item.code == value.code && item.position == value.position)
                    return false
                else
                    return true
            })
        }
    }
})